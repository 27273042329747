import React, { FC, PropsWithChildren } from "react";
import axios from "axios";

import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";

import useConfig from "./providers/configProvider/useConfig";

const AxiosClient: FC<PropsWithChildren> = ({ children }) => {
  const { config } = useConfig();

  axios.defaults.baseURL = config?.API_URI;

  if (config?.client === ConfigClientEnum.cgg) {
    axios.interceptors.request.use(
      (axiosConfig) => {
        axiosConfig.params = {
          ...axiosConfig.params,
          accessToken: "eV2njHP7yffnM30hB630nqwo3M5rllxUPoQ70zCdvjg==",
        };
        return axiosConfig;
      },
      (err) => {
        console.error("axios err: ", err);
      }
    );
  }

  return <>{children}</>;
};

export default AxiosClient;
