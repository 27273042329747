import { createTheme, Theme } from "@mui/material/styles";

import logo from "@assets/img/new-cgg-logo.png";

import { GLOBAL_CONSTANTS } from "./themeConfig";

const cggTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#6BCBE0",
      light: "#242627",
      dark: "#464747",
    },
    secondary: {
      dark: "#B1A89F",
      main: "#FFFFFF",
      light: "#554E47",
    },
    background: {
      default: "#554E47",
      paper: "#3E3934",
    },
    text: {
      secondary: "#2E2A27",
      primary: "#FFFFFF",
    },
    error: {
      main: "#ff0000",
    },
  },
  customColors: {
    privacyBg: "#FFFFFF",
    listViewColor: "#757675",
    inputMaskColor: "#5E5851",
    numpadButtonColor: "#6B6661",
    startPageBackgroundColor: "#3E3934",
    rootPagesBgColor: "#3E3934",
    inputDisabledBackgroundColor: "#2E2A27",
    inputDisabledFontColor: "#B1A89F",
    privacyTextColor: "#242627",
    maskedInputBoxShadow: "0px 0px 20px 0px #0000000F",
    ratingNumberSeparatorColor: "#554E47",
    dark20: "#898D8D33",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Avenir",
  },
  img: {
    logo,
    mobileLogo: logo,
  },
  custom: {
    headerAndFooterHeight: GLOBAL_CONSTANTS.HEADER_AND_FOOTER_HEIGHT,
    headerAndFooterHeightKiosk: GLOBAL_CONSTANTS.HEADER_AND_FOOTER_HEIGHT_KIOSK,
    welcomePage: {
      fontSizes: {
        title: "4.5rem",
        titleTablet: "3.5rem",
        titleMobile: "2rem",
      },
    },
    surveyPage: {
      fontSizes: {
        title: "5rem",
        titleTablet: "4rem",
        titleMobile: "2rem",
        ratingDesktop: "1rem",
        ratingTablet: "1rem",
        ratingMobile: "0.75rem",
      },
    },
    scanPage: {
      fontSizes: {
        title: "4rem",
      },
    },
    photosPage: {
      fontSizes: {
        title: "50px",
        shareEmailTitle: "4rem",
        shareEmailTitleTablet: "3rem",
        shareEmailTitleMobile: "2rem",
        shareSuccessTitle: "5rem",
        shareSuccessTitleTablet: "3rem",
        shareSuccessTitleMobile: "1.75rem",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Avenir';
          font-style: normal;
          src: url('/fonts/Avenir-Next.woff') format('woff');
        }

        * {
          font-family: 'Avenir !important';
        }

        button, p, h1, h2, h3, h4, h5, h6, li, input {
          font-family: 'Avenir !important';
        }

        .MuiBox-root {
          font-family: 'Avenir' !important;
        }

        .MuiButton-root {
          font-family: 'Avenir' !important;
        }

        .MuiTypography-root {
          font-family: 'Avenir' !important;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }

        .Mui-focused {
          * {
            border: none !important;
          }
        }

        p, img, svg {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-drag: none;
        }
      `,
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "auto",
          width: "auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#898D8D",
            color: "#B1B3B5",
          },
        },
      },
    },
  },
});

export default cggTheme;
