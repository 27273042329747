import { createTheme, Theme } from "@mui/material/styles";

import logo from "@assets/svg/sn-logo.svg";
import mobileLogo from "@assets/svg/sn-mobile-logo.svg";

import { GLOBAL_CONSTANTS } from "./themeConfig";

const snTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ECAA37",
      light: "#FFFFFF",
      dark: "#242627",
    },
    secondary: {
      dark: "#898D8D",
      main: "#242627",
      light: "#E7E7E7",
    },
    background: {
      default: "#ffffff",
      paper: "#F7F7F7",
    },
    text: {
      secondary: "#FFFFFF",
      primary: "#000000",
    },
    error: {
      main: "#ff0000",
    },
  },
  customColors: {
    privacyBg: "#404446",
    listViewColor: "#242627",
    inputMaskColor: "#B1B3B5",
    numpadButtonColor: "#242627",
    startPageBackgroundColor: "#FFFFFF",
    rootPagesBgColor: "#F7F7F7",
    inputDisabledBackgroundColor: "#E7E7E7",
    inputDisabledFontColor: "#B1B3B5",
    privacyTextColor: "#E7E7E7",
    maskedInputBoxShadow: "0px 0px 40px 0px #898D8D1F",
    ratingNumberSeparatorColor: "#E7E7E7",
    dark20: "#898D8D33",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Gotham",
  },
  img: {
    logo,
    mobileLogo,
  },
  custom: {
    headerAndFooterHeight: GLOBAL_CONSTANTS.HEADER_AND_FOOTER_HEIGHT,
    headerAndFooterHeightKiosk: GLOBAL_CONSTANTS.HEADER_AND_FOOTER_HEIGHT_KIOSK,
    welcomePage: {
      fontSizes: {
        title: "7.5rem",
        titleTablet: "5rem",
        titleMobile: "3rem",
      },
    },
    surveyPage: {
      fontSizes: {
        title: "6.5rem",
        titleTablet: "5rem",
        titleMobile: "4rem",
        ratingDesktop: "1.25rem",
        ratingTablet: "1rem",
        ratingMobile: "1rem",
      },
    },
    scanPage: {
      fontSizes: {
        title: "6rem",
      },
    },
    photosPage: {
      fontSizes: {
        title: "72px",
        shareEmailTitle: "6.5rem",
        shareEmailTitleTablet: "5rem",
        shareEmailTitleMobile: "3rem",
        shareSuccessTitle: "6.5rem",
        shareSuccessTitleTablet: "3.5rem",
        shareSuccessTitleMobile: "3rem",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/Gotham-Book.woff') format('woff');
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: url('/fonts/Gotham-Bold.woff') format('woff');
        }

        @font-face {
          font-family: 'Knockout';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/Knockout-Regular.woff') format('woff');
        }

        body {
          font-family: 'Gotham', Arial, sans-serif;
        }

        h1, .MuiTypography-h1 {
          font-family: 'Knockout' !important;
        }

        h2, .MuiTypography-h2 {
          font-family: 'Knockout' !important;
        }

        h3, .MuiTypography-h3 {
          font-family: 'Knockout' !important;
        }

        .MuiButton-root {
          font-family: 'Knockout' !important;
        }

        .Mui-focused {
          * {
            border: none !important;
          }
        }

        .MuiTypography-root {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }

        p, img, svg {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-drag: none;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Gotham",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "auto",
          width: "auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#898D8D",
            color: "#B1B3B5",
          },
        },
      },
    },
  },
});

export default snTheme;
