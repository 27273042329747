import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";

export const GLOBAL_CONSTANTS = {
  HEADER_AND_FOOTER_HEIGHT: 195,
  HEADER_AND_FOOTER_HEIGHT_KIOSK: 242,
};

export type ExperienceToComponentType<T> = {
  [key in ConfigClientEnum]: T;
};

export const getConfigValues = <T>(
  themeConfig: ExperienceToComponentType<T>,
  venue?: ConfigClientEnum
): T => {
  return (
    themeConfig[venue as keyof typeof themeConfig] ||
    themeConfig[ConfigClientEnum.sn]
  );
};
